<template>
    <b-badge class="tag-badge mr-1">
        {{ tag.nom }}
    </b-badge>
</template>

<script>
    export default {
        name: "BorrowableTagBadge",
        props: {
            tag: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>
    .tag-badge {
        color: #e81c04;
        background-color: #f0b967d0;
    }
</style>